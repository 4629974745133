<template>
	<!-- 单词速记 -->
	<div class="com_conbox wordsReadCom">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="" />
		</audio>
		<div class="b_titlebox" v-if="showtype>0">
			<div class="box1">
				<span class="span">{{stepNum==3?'单词补充':'单词速记'}}</span>
			</div>
		</div>
		<div class="imgclose" @click="handleclose">
			<img class="img100" src="../../assets/static/Frame3465600.png"/>
		</div>
		<!-- 音频 -->
		<div class="content-panel">
			<div class="block30"></div>
			<!-- 第一轮 -->
			<div class="startbox" v-if="showtype==0&&stepNum==1">
				<div class="b_titlebox">
					<div class="box1"><span class="span">单词速记</span></div>
				</div>
				<div class="imgbox"><img class="icon" src="../../assets/logo1.png"/></div>
				<div class="title1">自然拼读助力单词速记！</div>
				<div class="btn1" @click="handlenext(1)">Let‘s go！</div>
			</div>
			<!-- 第二轮 -->
			<div class="startbox" v-if="showtype==0&&stepNum==2">
				<div class="b_titlebox">
					<div class="box1"><span class="span">单词速记</span></div>
				</div>
				<div class="imgbox"><img class="icon" src="../../assets/logo1.png"/></div>
				<div class="title1">下一步，看中文，忆英文！</div>
				<div class="btn1" @click="handlenext(1)">Let‘s go！</div>
			</div>
			<!-- 第三轮 -->
			<div class="startbox" v-if="showtype==0&&stepNum==3">
				<div class="b_titlebox">
					<div class="box1"><span class="span">单词巩固</span></div>
				</div>
				<div class="imgbox"><img class="icon" src="../../assets/logo1.png"/></div>
				<div class="title1">单词速记已完成，进行训练巩固！</div>
				<div class="btn1" @click="handlenext(1)">Let‘s go！</div>
			</div>
			<div class="xuexibox" v-if="showtype>0&&stepNum>0&&stepNum<3">
				<!-- <div class="title1">
					<img class="img100" src="../../assets/static/dcsj.png"/>
				</div> -->
				<div class="block20"></div>
				<div class="wordbox">
					<div class="wordtext" v-if="stepNum==1 || showtype>1">
						{{wordobj.word || "word"}}
					</div>
					<div class="linsten-block">
						<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<span class="span" v-if="wordobj.phAm">美 /{{wordobj.phAm}}/</span>
					</div>
					<div class="progressbox">剩余学习单词：{{wordList.length}}</div>
				</div>
				<!-- 音标列表 -->
				<div class="say-list" v-if="(stepNum==1 || showtype>1)&&wordobj.syllableList.length>0">
					<div class="say-item" v-if="wordobj.syllableList[0]" :style="{color: colorlist[0]}">{{wordobj.syllableList[0]}}</div>
					<template v-for="(item,index) in wordobj.syllableList" v-if="index != 0">
						<div :key="index" class="line">-</div>
						<div class="say-item" v-if="index<=5" :style="{color: colorlist[index]}">{{item}}</div>
						<div class="say-item" v-if="index>5" :style="{color: colorlist[0]}">{{item}}</div>
					</template>
				</div>
				<div class="block20"></div>
				<!-- 词态 -->
				<div class="wordTpyebox" v-if="showtype>1 || stepNum==2">
					<div class="desc-item" v-for="(sitem,index) in wordobj.wordTpye" :key="index" v-if="sitem.part">
						<span>{{ sitem.part }}</span>
						<span class="desc2" v-for="(mitem, mindex) in sitem.means"
								:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
						</span>
					</div>
				</div>
				<div class="wordTpyebox" v-if="showtype==1&&stepNum==1">
					<div class="youkonw">是否认识？</div>
				</div>
				<!-- 次数 -->
				<div class="csbottombox">
					<div class="left">
						<div class="text text1">记忆时间：{{studyTimertext}}</div>
						<div class="text text2">学习错误数：{{cuoNum}}</div>
						<div class="text text3">学习次数：{{studyNum}}</div>
						
					</div>
					<div class="right" v-if="showtype==1">
						<div class="btn1" @click="handlerenshi(1)">√<span v-html="'&nbsp;&nbsp;'"></span>认识</div>
						<div class="block20"></div>
						<div class="btn1 btn2" @click="handlerenshi(2)">x<span v-html="'&nbsp;'"></span>不认识</div>
					</div>
					<div class="right" v-if="showtype==2">
						<div class="btn1" @click="handlerember(1)">√<span v-html="'&nbsp;&nbsp;'"></span>记住了</div>
						<div class="block20"></div>
						<div class="btn1 btn2" @click="handlerember(2)">x<span v-html="'&nbsp;'"></span>忘记了</div>
					</div>
					<div class="right" v-if="showtype==3">
						<div class="btn1" @click="handlenextOne">下一个</div>
					</div>
				</div>
			</div>
			<!-- 单词补充 -->
			<div class="xuexibox" v-if="showtype>0&&stepNum==3">
				<!-- <div class="title1">
					<img class="img100" src="../../assets/static/dcbc.png"/>
				</div> -->
				<div class="block20"></div>
				<div class="wordbox">
					<div class="wordtext">
						{{wordspellindex}}.
					</div>
					<div class="linsten-block" v-if="showCn">
						<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<div class="word-desc clamp" v-if="wordobj.wordTpye.length>0">
						    <span>{{ wordobj.wordTpye[0].part }}</span>
						    <span class="desc2" v-for="(mitem, mindex) in wordobj.wordTpye[0].means"
						    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
						    </span>
						</div>
					</div>
					<div class="linsten-block" v-if="!showCn">
						<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<div class="btnshow" @click="showCn= true">显示释义</div>
					</div>
					<div class="progressbox">剩余学习单词：{{wordList.length}}</div>
				</div>
				<div class="buchongbox">
					<div class="wordbc">
						<div class="label1">补全单词：</div>
						<div class="inputlistbox">
							<div class="itemobj" v-for="(item, index) in wordspell" :key="index">
								<div class="text" v-if="!item.ifspell">{{item.word}}</div>
								<input class="input" maxlength="1" :class="`input${index}`" v-if="item.ifspell" v-model="wordListValue[index]" :ref="`input${index}`" @input="handleInput(index)" @keydown.delete="handleDelete(index)" @keydown.enter="handletijiao(index)"/>
							</div>
						</div>
					</div>
					<div class="wordbc1">
						<div class="text1" v-if="showAnswer">正确答案：<span>{{wordobj.word}}</span></div>
					</div>
				</div>
				<!-- 次数 -->
				<div class="csbottombox">
					<div class="left">
						<div class="text text1">记忆时间：{{studyTimertext}}</div>
						<div class="text text2">学习错误数：{{cuoNum}}</div>
						<div class="text text3">学习次数：{{studyNum}}</div>
					</div>
					<div class="right">
						<div class="btn1" @click="handletijiao">提交</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
	<!-- end-->
</template>

<script>
	import {mapState} from "vuex";
	export default {
		props: {
			type: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		watch: {
			list: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal&&newVal.length>0){
						this.wordList = newVal
						this.initfunc()
					}
			    },
			},
		},
		data() {
			return {
				showtype:0,//未开始 1是第一轮，2是第二步骤，3是下一个
				stepNum:1,//第一轮
				isLinstening: false, //是否正在播放音频
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				awtitloading: false, //防止多次点击
				colorlist:["#12D091","#F79B17","#0065CC","#7500EC","#FF434E","#FF439D","#0E82EC"],
				showCn:false,//显示词义
				showAnswer:false,//显示答案
				wordspell:[],//新建输入框，解决无法双向绑定问题
				wordspellindex:1,//当前拼第几个
				wordListValue:[],//新建输入框，解决无法双向绑定问题
				studyTimer:null,//学习时间 秒
				studytime:0,//学习时间
				studyTimertext:"00:00:00",
				cuoNum:0,//错次
				studyNum:0,//错次
				successList:[],//新的列表
			};
		},
		//状态值
		computed: {
			...mapState(['myReadingObj','userInfo', 'isLogin']),
		},
		beforeDestroy() {
		    // 清除定时器
			if(this.studyTimer){
				clearInterval(this.studyTimer)
			}
		},
		methods: {
			//初始化
			initfunc(){
				this.wordList.forEach((item, index) => {
					item["studyNum"] = 0
					item["cuoNum"] = 0
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
					try{
						item["syllableList"] = item.syllables.split('#').filter(function(sitem){
							return sitem != "";
						})
					}catch(e){
						item["syllableList"] = []
					}
				});
				this.wordindex = 0
				this.showtype = 0 //未开始
				this.stepNum = 1
				this.showCn = false //显示词义
				this.showAnswer = false //显示答案
				this.cuoNum = 0
				this.studyNum = 0 //错次
				this.successList = []
			},
			//刷新页面单词
			refreshpage(){
				var _this = this
				if(this.wordList.length>0){
					this.wordobj = this.wordList[this.wordindex]
					//播放
					this.playAudioCom(this.wordobj.phAmMp3)
					this.awtitloading = false
					if(this.stepNum==3){
						this.showCn = false //显示词义
						this.showAnswer = false //显示答案
						this.wordListValue = []
						this.wordspell = this.wordobj.wordspell
						let nextinput = -1
						for(let i = 0;i<this.wordspell.length;i++){
							if(this.wordspell[i].ifspell){
								this.wordListValue.push("")
								if(nextinput<0){
									nextinput = i
								}
							}else{
								this.wordListValue.push(this.wordspell[i].word)
							}
						}
						//聚焦
						this.$nextTick(() => {
						    const nextInput = _this.$refs[`input${nextinput}`];
						    if (nextInput) {
						        nextInput[0].focus();
						    }
						});
					}
				}else{
					//没有了，进入单词巩固
					if(this.stepNum==3){
						//保留学习时长
						var params = {
							userUuid:this.userInfo.uuid,
							studyTime:this.studytime
						}
						this.$common.saveStudyTime(params)
						//完成训练
						var params = {
							recordType:0,//测试类型 0:单词速记 1:单词连连 2:听音拼写 3:单元测试
							bookId:this.wordobj.bookId,
							booksLessonId:this.wordobj.lessonId,
							userUuid:this.userInfo.uuid,
						}
						this.$common.saveBooksLessonRecord(params)
						this.$emit("comfirm")
						return;
					}
					this.showtype = 0
					this.stepNum += 1
					this.wordList = this.successList
					this.successList = []
					if(this.stepNum==3){
						//单词巩固
						this.wordList.forEach((item, index) => {
							item["wordspell"] = []
							item["inputkey"] = item.word.replace(/[^a-zA-Z]/g, "").toLowerCase();
							try{
								let wordFill = item.wordFill.split('#').filter(function(sitem){
									return sitem != "";
								})
								for(let i = 0;i<item.inputkey.length;i++){
									if(wordFill.indexOf(i+"")>=0){
										item.wordspell.push({
											word:item.inputkey[i],
											ifspell:true,//是否输入
										})
									}else{
										item.wordspell.push({
											word:item.inputkey[i],
											ifspell:false,//是否输入
										})
									}
								}
							}catch(e){
								for(let i = 0;i<item.inputkey.length;i++){
									if(i==0){
										item.wordspell.push({
											word:item.inputkey[i],
											ifspell:true,//是否输入
										})
									}else{
										item.wordspell.push({
											word:item.inputkey[i],
											ifspell:false,//是否输入
										})
									}
								}
							}
							//console.log(item.wordspell)
						});
					}
				}
			},
			//提交
			handletijiao(){
				var _this = this
				var newrecords = this.wordListValue.filter(item => !item);
				if(newrecords.length>0){
					this.$message.error("请先拼写完！")
					return false;
				}
				var keywords = this.wordListValue.join('');
				if(this.showAnswer){
					return;
				}
				if(keywords==this.wordobj.inputkey){
					this.$message.success("拼写正确")
					this.successList.push(this.wordobj)
					let wordList = this.wordList.filter(item => item.wordId !== this.wordobj.wordId);
					this.wordList = wordList
					this.refreshpage()
				}else{
					this.$message.error("拼写错误")
					this.showAnswer = true
					//不记住
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					this.$common.userErrWord(params)
				}
			},
			//认识
			async handlerenshi(type){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				if(type==1){
					//认识
					this.showtype = 2
				}else{
					//不认识
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					this.$common.userErrWord(params)
					this.wordobj.cuoNum +=1
					this.wordobj.studyNum +=1
					this.cuoNum +=1
					this.studyNum +=1 //错次
					this.showtype = 3
				}
				//播放
				this.playAudioCom(this.wordobj.phAmMp3)
				this.awtitloading = false
			},
			//下一个
			handlenextOne(){
				let wordList = this.wordList.filter(item => item.wordId !== this.wordobj.wordId);
				this.wordList = this.insertObjectIntoArray(this.wordobj,wordList)
				this.showtype = 1
				this.refreshpage()
			},
			//记住了
			async handlerember(type){
				var _this = this
				if(this.awtitloading){
					console.log("awtitloading")
					return false;
				}else{
					this.awtitloading = true
				}
				this.wordobj.studyNum +=1
				this.studyNum +=1 //错次
				if(type==1){
					//记住
					this.successList.push(this.wordobj)
					let wordList = this.wordList.filter(item => item.wordId !== this.wordobj.wordId);
					this.wordList = wordList
					this.showtype = 1
					this.refreshpage()
				}else{
					//不记住
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					this.$common.userErrWord(params)
					this.wordobj.cuoNum +=1
					this.cuoNum +=1
					//下一个
					this.handlenextOne()
				}
				this.awtitloading = false
			},
			//计时器
			startTimer() {
				var _this = this
			    const oneSecond = 1000;
				let curtime = 0
				this.studytime = 0 //单位秒
			    this.studyTimer = setInterval(function() {
					const hours = Math.floor(curtime / 3600);
					const minutes = Math.floor((curtime % 3600) / 60);
					const seconds = curtime % 60;
					const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
					//console.log(time); // 输出格式化的时间
					_this.studyTimertext = time
					curtime++;
					_this.studytime = curtime
			    }, oneSecond);
			},
			//监听删除
			handleDelete(index){
				var _this = this
				if(!this.wordListValue[index]){
					console.log("delkong",index)
					var nextindex = index - 1
					if(nextindex<0){
						console.log("end")
						return;
					}
					while (!_this.wordspell[nextindex].ifspell) {
						nextindex--;
						if(nextindex<0){
							console.log("while end")
							break;
						}
					}
					if(nextindex<0){
						console.log("end")
						return;
					}
					const nextInput = _this.$refs[`input${nextindex}`];
					//console.log(nextInput)
					if (nextInput) {
					    nextInput[0].focus();
					}
				}
			},
			//监听输入
			handleInput(index) {
				var _this = this
				this.showAnswer = false
			    // 当前输入框内容变化时，聚焦下一个输入框
				this.wordListValue[index] = this.wordListValue[index].replace(/[^a-zA-Z0-9]/g, '')
				if(!this.wordListValue[index]){
					console.log("kong",index)
					return;
				}
				var nextindex = index + 1
			    this.$nextTick(() => {
					if(nextindex>=_this.wordspell.length){
						console.log("end")
						return;
					}
					while (!_this.wordspell[nextindex].ifspell) {
						nextindex++;
						if(nextindex>=_this.wordspell.length){
							console.log("while end")
							break;
						}
					}
					if(nextindex>=_this.wordspell.length){
						console.log("end")
						return;
					}
			        const nextInput = _this.$refs[`input${nextindex}`];
					//console.log(nextInput)
			        if (nextInput) {
			            nextInput[0].focus();
			        }
			    });
			},
			//关闭
			handleclose(){
				var _this = this
				if(this.showtype>0){
					this.$confirm("当前处于学习中，确认是否退出？", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.$emit('onClose')
						if(_this.studyTimer){
							clearInterval(_this.studyTimer)
						}
					}).catch(() => {});
				}else{
					this.$emit('onClose')
				}
			},
			//下一个
			handlenext(type){
				this.showtype = type
				if(type>0&&this.studyTimer==null){
					//计时器
					this.startTimer()
				}
				//刷新页面单词
				this.refreshpage()
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			//插入数组
			insertObjectIntoArray(obj, array) {
				if (array.length < 2) {
					array.push(obj);
				} else {
					let index = Math.floor(Math.random() * (array.length - 1)) + 1;
					array.splice(index, 0, obj);
				}
				return array;
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			
		},
	};
</script>

<style lang="scss" scoped>
	/deep/ .el-input__inner{
		border: none;
	}
	.com_conbox {
		width: 100%;
		position: relative;
		z-index: 18;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
	}
	.b_titlebox{
		width: 263px;
		height: 80px;
		border-radius: 20px;
		background: linear-gradient(90deg, #E8F0FB 0%, #99B8E3 100%);
		box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: -18px;
		top: -28px;
		z-index: 2;
		.box1{
			width: 231px;
			height: 62px;
			border-radius: 20px;
			background: linear-gradient(180deg, #2BB9FF 0%, #06F 100%);
			box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
			.span{
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
		}
	}
	.content-panel {
		width: 100%;
		min-height: 678px;
		position: relative;
		z-index: 1;
		padding: 28px 32px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.startbox{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.b_titlebox{
				position: relative !important;
				left: 0;
				top: 0;
			}
			.imgbox{
				width: 82%;
				height: 185px;
				margin-top: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.icon{
					width: 210px;
					height: 137px;
				}
			}
			.title1{
				margin-top: 32px;
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		.xuexibox{
			width: 82%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title1{
				width: 192px;
				height: 63px;
			}
			.wordbox{
				width: 100%;
				height: 64px;
				display: flex;
				align-items: center;
				.wordtext{
					color: #273B90;
					font-size: 32px;
				}
				.linsten-block{
					flex: 1;
					padding-left: 38px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					.linsten-btn{
						width: 48px;
						height: 48px;
						cursor: pointer;
						flex-shrink: 0;
					}
					.span{
						margin-left: 8px;
						font-size: 20px;
					}
					.text{
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
					}
					.word-desc {
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
						.desc2{
							margin-left: 4px;
						}
					}
				}
				.progressbox{
					width: 158px;
					flex-shrink: 0;
					margin-left: auto;
					font-size: 18px;
					color: #273B90;
					text-align: right;
				}
			}
			.say-list{
				width: 100%;
				padding: 38px 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.say-item {
					font-size: 64px;
				}
				.line {
					margin: 0 8px;
					color: #273B90;
					font-size: 64px;
				}
			}
			.wordTpyebox{
				width: 100%;
				padding: 28px 58px;
				box-sizing: border-box;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.youkonw{
					width: 100%;
					color: #222;
					text-align: center;
					font-size: 20px;
				}
				.desc-item {
					display: flex;
					flex-wrap: wrap;
					margin: 8px 0;
					line-height: 18px;
					font-size: 16px;
					.desc2{
						margin-left: 4px;
					}
					span:last-child {
						color: #222222;
						margin-right: 5px;
					}
				}
			}
			.csbottombox{
				width: 100%;
				margin-top: 28px;
				display: flex;
				align-items: center;
				.left{
					color: #FFF;
					font-size: 24px;
					.text2,.text3{
						margin-top: 10px;
					}
				}
				.right{
					margin-left: auto;
					.btn1{
						width: 160px;
						height: 48px;
						color: #12D091;
						line-height: 48px;
						text-align: center;
						border-radius: 25px;
						background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
						font-size: 20px;
						cursor: pointer;
						// font-weight: bold;
					}
					.btn1:hover{
						transform: scale(1.1);
					}
					.btn2{
						color: #0772FF;
						font-weight: normal;
					}
				}
			}
			.btnshow{
				margin-left: 8px;
				width: 112px;
				height: 42px;
				color: #FFFFFF;
				line-height: 42px;
				text-align: center;
				border-radius: 12px;
				background: #3773F5;
				font-size: 20px;
				cursor: pointer;
			}
			.buchongbox{
				width: 100%;
				height: 168px;
				padding: 28px 58px 0;
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.wordbc{
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
				}
				.wordbc1{
					height: 58px;
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
					.text1{
						display: flex;
						align-items: center;
						font-size: 20px;
						span{
							color: #F50;
						}
					}
				}
				.inputlistbox{
					display: flex;
					align-items: center;
					.itemobj{
						display: flex;
						align-items: center;
						font-size: 32px;
						color: #FF5500;
						height: 42px;
						margin-right: 8px;
						.text{
							margin: 0 5px;
						}
						.input{
							width: 38px;
							color: #FF5500;
							height: 42px;
							line-height: 42px;
							border: none;
							border-bottom: 1px solid #3773F5;
							text-align: center;
							font-size: 32px;
						}
					}
				}
			}
		}
	}
	
</style>