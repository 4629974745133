<template>
	<div class="page">
		<div class="w1200 study_page">
			<div class="block30"></div>
			<topHeader @changebook="showTcType= 8"></topHeader>
			<div class="study_box_one">
				<img class="bgimg" src="../assets/static/study_bg.png"/>
				<div class="conbox">
					<div class="top_box">
						<div class="backicon pointer" @click="navigato('/',{})"><img class="img100" src="../assets/static/left_go.png"/></div>
						<div class="textbox1">
							<div class="text1">单元学练</div>
							<div class="text2">练习流程建议：单词速记-词义连连-听音拼写-单元测试</div>
						</div>
						<div class="titlename_box">
							<div class="itemobj fontsize14" @click="handlebookdtype(index)" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
								{{item}}
							</div>
						</div>
					</div>
					<div class="center_box">
						<div class="itemobj" v-for="(item,index) in bookddanyuan" :key="index" @click="handleUnit(item)">
							<div class="icon" v-if="item.userBooksLesson&&item.userBooksLesson[bookdtypeFomat[bookdtypeindex]]">
								<img class="img100" src="../assets/static/pass.png"/>
							</div>
							<div class="text">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="block30"></div>
		</div>
		<!-- 弹窗 -->
		<div class="show_tc_box" v-if="showTcType>0">
			<div class="zezhao10"></div>
			<div class="w1200" v-if="showTcType==1">
				<wordsReadCom :list="wordList" @onClose="handleclosefun" @comfirm="studyComfirm"></wordsReadCom>
			</div>
			<!-- 词义连连 -->
			<div class="w1200" v-if="showTcType==2">
				<wordLink :type="2" :list="wordList" @onClose="handleclosefun" @comfirm="studyComfirm"></wordLink>
			</div>
			<!-- 听音拼写 -->
			<div class="w1200" v-if="showTcType==3">
				<spellCom :list="wordList" @onClose="handleclosefun" @comfirm="studyComfirm"></spellCom>
			</div>
			<!-- 切换词书 -->
			<div class="w1200" v-if="showTcType==8">
				<changeBook @onClose="handleclosefun" @refrshData="getBooksLessonRecord"></changeBook>
			</div>
		</div>
		<!-- 完成测试 -->
		<div class="show_tc_box" v-if="showfinish">
			<div class="zezhao10"></div>
			<div class="w1200 test_finish">
				<div class="imgclose" @click="handleclosefun">
					<img class="img100" src="@/assets/static/Frame3465600.png"/>
				</div>
				<div class="title1">
					<img class="img100" src="@/assets/static/test_finish.png"/>
				</div>
				<div class="btn1" @click="successcomfirm">Fighting</div>
			</div>
		</div>
		

		<div class="block30"></div>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordsReadCom from "@/components/wordCard/wordsReadCom.vue";
	import wordLink from "@/components/wordCard/wordLink.vue";
	import spellCom from "@/components/wordCard/spellCom.vue";
	import changeBook from "@/components/wordCard/changeBook.vue";
	export default {
		components:{
			topHeader,
			wordsReadCom,
			wordLink,
			spellCom,
			changeBook
		},
		data() {
			return {
				bookdtypeList:["单词速记","词义连连","听音拼写","单元测试"],
				bookdtypeFomat:["dcsjStatus","dcllStatus","typxStatus","dycsStatus"],
				bookdtypeindex:0,//下标
				booksLessonRecord:{},//词书单元学习情况
				bookddanyuan:[],
				showTcType:0,//1是单词速记，依次过去
				showfinish:false,
				wordList:[],
			};
		},
		mounted: function() {
			if(this.$route.query&&this.$route.query.type){
				this.bookdtypeindex = Number(this.$route.query.type)
			}
			//学习词书单元情况
			this.getBooksLessonRecord()
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'studyingData','myBooks']),
		},
		methods: {
			...mapMutations(["SET_Data"]),
			navigato(url,query) {
				this.$util.routerPath(url,query)
			},
			//学习词书单元情况
			getBooksLessonRecord(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					bookId:this.myReadingObj.bookId
				}
				_this.$http.post("app/word/userBooks/indexBooksLessonRecord", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data&&res.data.length>0){
							_this.booksLessonRecord = res.data[0]
							_this.bookddanyuan = res.data[0].lessonList
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//学习完成
			studyComfirm(){
				this.showfinish = true
				this.showTcType = 0
				this.wordList = []
				if(this.showTcType==1){
					//单词速记
				}
			},
			successcomfirm(){
				//刷新学习词书单元情况
				this.getBooksLessonRecord()
				this.showfinish = false
			},
			//关闭
			handleclosefun(){
				this.showTcType = 0
				this.showfinish = false
				this.wordList = []
			},
			//点击单元
			handleUnit(item){
				if(item.userBooksLesson&&this.bookdtypeindex==3){
					if(item.userBooksLesson.dcsjStatus==0 || item.userBooksLesson.dcllStatus==0 || item.userBooksLesson.typxStatus==0){
						this.$message({
						    showClose: true,
							offset:158,
						    message: '请先学习这个单元的单词速记-词义连连-听音拼写！',
						    type: 'info'
						});
						return false;
					}
				}else{
					if(this.bookdtypeindex==3){
						this.$message({
						    showClose: true,
							offset:158,
						    message: '请先学习这个单元的单词速记-词义连连-听音拼写！',
						    type: 'info'
						});
						return false;
					}
				}
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid,
					bookId:this.myReadingObj.bookId,
					lessonId:item.id,
					id:item.id
				}
				if(_this.bookdtypeindex==3){
					//单元测试
					var studyingData = _this.studyingData
					studyingData.lessonexamobj = {
						lessonId:params.lessonId,
						bookId:params.bookId,
					}
					_this.SET_Data({ key: 'studyingData', value:studyingData})
					_this.$util.routerPath('/examPage',{type:2})
					return;
				}
				showLoading()
				_this.$http.post("app/word/booksLesson/detailWord", params).then(function(res) {
					hideLoading()
					if (res.code === 200) {
						_this.wordList = _this.shuffleArray(res.data.wordList)
						_this.showTcType = _this.bookdtypeindex + 1
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
				
			},
			//下标
			handlebookdtype(type){
				this.bookdtypeindex = type
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	.study_page{
		position: relative;
		z-index: 2;
	}
	.study_box_one{
		width: 100%;
		position: relative;
		.bgimg{
			width: 100%;
			height: 100%;
			object-fit: fill;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		.conbox{
			width: 96%;
			min-height: 694px;
			padding: 0 22px 28px;
			position: relative;
			z-index: 5;
			margin-left: 4%;
			margin-top: 18px;
			border-radius: 40px;
			border: 4px solid rgba(255, 255, 255, 0.00);
			background: linear-gradient(180deg, #FFF 16.74%, #F2F5FA 60.03%, #E1EAFB 99.98%);
			.top_box{
				padding: 24px 0;
				margin: 0 10px;
				display: flex;
				align-items: flex-start;
				border-bottom: 1px solid #F0F0F0;
				.backicon{
					width: 32px;
					height: 32px;
					margin-right: 12px;
					flex-shrink: 0;
				}
				.textbox1{
					flex: 1;
					.text1{
						color: #222;
						font-size: 24px;
					}
					.text2{
						margin-top: 5px;
						color: #666;
						font-size: 16px;
					}
				}
				.titlename_box{
					margin-left: 12px;
					padding: 5px;
					display: flex;
					align-items: center;
					border-radius: 100px;
					border: 1px solid #E5E6EB;
					.itemobj{
						width: 80px;
						height: 28px;
						line-height: 28px;
						text-align: center;
						border-radius: 100px;
						background: #ffffff;
						color: #666;
						cursor: pointer;
					}
					.active{
						background: #3773F5;
						color: #ffffff;
					}
				}
			}
			.center_box{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				.itemobj{
					width: 338px;
					margin: 38px 10px 0;
					height: 110px;
					border-radius: 20px;
					border: 3px solid #50FFFF;
					background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					cursor: pointer;
					.icon{
						width: 96px;
						height: 42px;
						position: absolute;
						right: -8px;
						top: -8px;
						z-index: 2;
					}
					.text{
						font-size: 32px;
						background: linear-gradient(180deg, #ffffff 24.53%, #EAF0FF 75.47%);
						background-clip: text;
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}
	}
	.test_finish{
		height: 582px;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		z-index: 18;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
		.title1{
			width: 590px;
			height: 154px;
		}
		.btn1{
			margin-top: 48px;
			width: 160px;
			height: 48px;
			color: #0772FF;
			line-height: 48px;
			text-align: center;
			border-radius: 25px;
			background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
			font-size: 20px;
			cursor: pointer;
		}
	}
	
</style>