<template>
	<!-- 听音拼写 -->
	<div class="com_conbox">
		<!-- 音频 -->
		<audio controls ref="commonaudioCom" class="disnone">
			<source src="" />
		</audio>
		<div class="b_titlebox" v-if="showtype>0">
			<div class="box1">
				<span class="span">听音拼写</span>
			</div>
		</div>
		<div class="imgclose" @click="handleclose">
			<img class="img100" src="../../assets/static/Frame3465600.png"/>
		</div>
		<!-- 音频 -->
		<div class="content-panel">
			<div class="block30"></div>
			<!-- 第一轮 -->
			<div class="startbox" v-if="showtype==0">
				<div class="b_titlebox">
					<div class="box1"><span class="span">听音拼写</span></div>
				</div>
				<div class="imgbox"><img class="icon" src="../../assets/logo1.png"/></div>
				<div class="title1">听单词音频，拼写单词！</div>
				<div class="btn1" @click="handlenext(1)">Let‘s go！</div>
			</div>
			<!-- 内容 -->
			<div class="studylian_box" v-if="showtype==1">
				<!-- <div class="title1"><img class="img100" src="../../assets/static/typx.png"/></div> -->
				<div class="block20"></div>
				<div class="wordbox">
					<div class="wordtext">
						{{wordspellindex}}.
					</div>
					<div class="linsten-block" v-if="showCn">
						<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<div class="word-desc clamp" v-if="wordobj.wordTpye.length>0">
						    <span>{{ wordobj.wordTpye[0].part }}</span>
						    <span class="desc2" v-for="(mitem, mindex) in wordobj.wordTpye[0].means"
						    	:key="mindex">{{ mitem }};<span v-html="'&nbsp;'"></span>
						    </span>
						</div>
					</div>
					<div class="linsten-block" v-if="!showCn">
						<div class="linsten-btn" @click="playAudioCom(wordobj.phAmMp3)">
							<img class="img100" src="@/assets/static/linsten.png"/>
						</div>
						<div class="btnshow" @click="showCn= true">显示释义</div>
					</div>
				</div>
				<div class="buchongbox">
					<div class="wordbc">
						<div class="label1">输入单词：</div>
						<div class="inputlistbox">
							<input class="input" placeholder="请输入" v-model="keyword" ref="input1" @input="handleinput" @keydown.enter="handletijiao()"/>
						</div>
					</div>
					<div class="wordbc1">
						<div class="text1" v-if="showAnswer">正确答案：<span>{{wordobj.word}}</span></div>
					</div>
				</div>
				<!-- 次数 -->
				<div class="csbottombox">
					<div class="left">
						<div class="text text1">记忆时间：{{studyTimertext}}</div>
						<div class="text text2">错词：{{cuoNum}}</div>
						<div class="text text3">学习次数：{{studyNum}}</div>
					</div>
					<div class="right">
						<div class="btn1" @click="handletijiao">提交</div>
					</div>
				</div>
				
			</div>
			<!-- 完成 -->
			<!-- <div class="finish_box" v-if="showtype>1">
				<div class="title1">
					<img class="img100" src="../../assets/static/spell_finish.png"/>
				</div>
				<div class="desc1">
					<div class="text1">28</div>
					<div class="text2">你的分数</div>
				</div>
				<div class="conbox">
					<div class="text text1">正确题目：<span>16</span></div>
					<div class="text text2">错误题目：<span>16</span></div>
				</div>
				<div class="btn1">Fighting</div>
			</div> -->
			
		</div>
	</div>
	<!-- end-->
</template>

<script>
	import {mapState,mapMutations} from "vuex";
	export default {
		props: {
			type: {
				type: Number,
				default: () => {
					return 0;
				}
			},
			list: {
				type: Array,
				default: () => {
					return [];
				}
			},
		},
		watch: {
			list: {
				immediate:true,
			    handler(newVal, objVal) {
					if(newVal&&newVal.length>0){
						this.wordList = newVal
						this.initfunc()
					}
			    },
			},
		},
		data() {
			return {
				showtype:2,//步骤
				isLinstening: false, //是否正在播放音频
				wordList:[],//单词列表
				wordobj:{},//当前学习的下标
				wordindex:0,//当前学习的下标
				wordspellindex:1,//当前拼第几个
				awtitloading: false, //防止多次点击
				showCn:false,//显示词义
				showAnswer:false,//显示答案
				keyword:"",//输入值
				studyTimer:null,//学习时间 秒
				studytime:0,//学习时间
				studyTimertext:"00:00:00",
				cuoNum:0,//错次
				studyNum:0,//错次
				successList:[],//新的列表
			};
		},
		//状态值
		computed: {
			...mapState(['myReadingObj','userInfo', 'studyingData']),
		},
		beforeDestroy() {
		    // 清除定时器
			if(this.studyTimer){
				clearInterval(this.studyTimer)
			}
		},
		methods: {
			...mapMutations(["SET_Data"]),
			//初始化
			initfunc(){
				this.wordList.forEach((item, index) => {
					item["studyNum"] = 0
					item["cuoNum"] = 0
					try{
						item["wordTpye"] =  JSON.parse(item.definition)
					}catch(e){
						item["wordTpye"] = []
					}
				});
				this.wordindex = 0
				this.showtype = 0 //未开始
				this.showCn = false //显示词义
				this.showAnswer = false //显示答案
				this.cuoNum = 0
				this.studyNum = 0 //错次
				this.successList = []
				//刷新页面单词
				this.refreshpage()
			},
			//刷新页面单词
			refreshpage(){
				var _this = this
				if(this.wordList.length>0){
					this.keyword = ""
					this.showCn = false
					this.wordobj = this.wordList[this.wordindex]
					//播放
					this.playAudioCom(this.wordobj.phAmMp3)
					this.awtitloading = false
					//聚焦
					this.$nextTick(() => {
					    const nextInput = _this.$refs.input1;
						//console.log(nextInput)
					    if (nextInput) {
					        nextInput.focus();
					    }
					});
				}else{
					var studyingData = this.studyingData
					studyingData.successwords = this.successList
					this.SET_Data({ key: 'studyingData', value:studyingData})
					this.$emit('onClose')
					//保留学习时长
					var params = {
						userUuid:this.userInfo.uuid,
						studyTime:this.studytime
					}
					this.$common.saveStudyTime(params)
					this.$util.routerPath('/spellPage',{type:1})
					
				}
			},
			//提交
			handletijiao(){
				var _this = this
				if(this.showAnswer){
					return;
				}
				let keywords = this.keyword.toLowerCase()
				let word = this.wordobj.word.replace(/[^a-zA-Z]/g, "").toLowerCase()
				if(keywords==word){
					this.$message.success("拼写正确")
					let findindex = this.successList.findIndex(item => item.wordId == this.wordobj.wordId);
					if(findindex<0){
						this.successList.push(this.wordobj)
					}
					this.wordobj.studyNum +=1
					this.studyNum +=1
					let wordList = this.wordList.filter(item => item.wordId !== this.wordobj.wordId);
					if(this.wordobj.cuoNum==1){
						//答错过的，随机插入后面去
						this.wordobj.cuoNum = 0
						this.wordList = this.insertObjectIntoArray(this.wordobj,wordList)
						//console.log(this.wordList)
					}else{
						this.wordList = wordList
					}
					this.wordspellindex +=1 
					this.refreshpage()
				}else{
					this.wordobj.cuoNum +=1
					this.wordobj.studyNum +=1
					this.cuoNum +=1
					this.studyNum +=1
					this.$message.error("拼写错误")
					this.showAnswer = true
					//不记住
					const params = {
						wordId:this.wordobj.wordId,
						bookId:this.wordobj.bookId,
						userUuid:this.userInfo.uuid
					}
					this.$common.userErrWord(params)
				}
			},
			//随机插入数组
			insertObjectIntoArray(obj, array) {
				if (array.length < 2) {
					array.push(obj);
				} else {
					let index = Math.floor(Math.random() * (array.length - 1)) + 1;
					array.splice(index, 0, obj);
				}
				return array;
			},
			//输入
			handleinput(){
				this.showAnswer = false
				this.keyword = this.keyword.replace(/[^a-zA-Z0-9]/g, '')
			},
			//播放音频
			playAudioCom(src) {
				var _this = this
				try{
					_this.$refs.commonaudioCom.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudioCom.load()
					    _this.$refs.commonaudioCom.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
			//计时器
			startTimer() {
				var _this = this
			    const oneSecond = 1000;
				let curtime = 0
				this.studytime = 0 //单位秒
			    this.studyTimer = setInterval(function() {
					const hours = Math.floor(curtime / 3600);
					const minutes = Math.floor((curtime % 3600) / 60);
					const seconds = curtime % 60;
					const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
					//console.log(time); // 输出格式化的时间
					_this.studyTimertext = time
					curtime++;
					_this.studytime = curtime
			    }, oneSecond);
			},
			//关闭
			handleclose(){
				var _this = this
				if(this.showtype>0){
					this.$confirm("当前处于学习中，确认是否退出？", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						_this.$emit('onClose')
					}).catch(() => {});
				}else{
					this.$emit('onClose')
				}
			},
			//下一个
			handlenext(type){
				this.showtype = type
				if(type>0&&this.studyTimer==null){
					//计时器
					this.startTimer()
				}
				//刷新页面单词
				this.refreshpage()
			},
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	/deep/ .el-input__inner{
		border: none;
	}
	.com_conbox {
		width: 100%;
		position: relative;
		z-index: 18;
		border-radius: 40px;
		background: linear-gradient(180deg, #63E0FF 0%, #24A7EF 100%);
		box-shadow: 0px -8px 4px 0px #2FC3EA inset;
		.imgclose{
			width: 40px;
			height: 40px;
			position: absolute;
			right: 32px;
			top: 28px;
			z-index: 2;
			cursor: pointer;
		}
	}
	.b_titlebox{
		width: 263px;
		height: 80px;
		border-radius: 20px;
		background: linear-gradient(90deg, #E8F0FB 0%, #99B8E3 100%);
		box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: -18px;
		top: -28px;
		z-index: 2;
		.box1{
			width: 231px;
			height: 62px;
			border-radius: 20px;
			background: linear-gradient(180deg, #2BB9FF 0%, #06F 100%);
			box-shadow: 0px 6px 8px 0px rgba(17, 13, 66, 0.25);
			display: flex;
			align-items: center;
			justify-content: center;
			.span{
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
		}
	}
	.content-panel {
		width: 100%;
		min-height: 678px;
		position: relative;
		z-index: 1;
		padding: 28px 16px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.startbox{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.b_titlebox{
				position: relative !important;
				left: 0;
				top: 0;
			}
			.imgbox{
				width: 82%;
				height: 185px;
				margin-top: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.icon{
					width: 210px;
					height: 137px;
				}
			}
			.title1{
				margin-top: 32px;
				color: #FFF;
				text-align: center;
				font-size: 32px;
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		.studylian_box{
			width: 82%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title1{
				width: 192px;
				height: 63px;
			}
			.wordbox{
				width: 100%;
				height: 64px;
				display: flex;
				align-items: center;
				.wordtext{
					color: #273B90;
					font-size: 32px;
				}
				.linsten-block{
					flex: 1;
					padding-left: 38px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					.linsten-btn{
						width: 48px;
						height: 48px;
						cursor: pointer;
						flex-shrink: 0;
					}
					.text{
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
					}
					.word-desc {
						flex: 1;
						margin-left: 8px;
						color: #273B90;
						font-size: 24px;
						.desc2{
							margin-left: 4px;
						}
					}
				}
			}
			.csbottombox{
				width: 100%;
				margin-top: 28px;
				display: flex;
				align-items: center;
				.left{
					color: #FFF;
					font-size: 24px;
					.text2,.text3{
						margin-top: 10px;
					}
				}
				.right{
					margin-left: auto;
					.btn1{
						width: 160px;
						height: 48px;
						color: #0772FF;
						line-height: 48px;
						text-align: center;
						border-radius: 25px;
						background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
						font-size: 20px;
						cursor: pointer;
					}
				}
			}
			.btnshow{
				margin-left: 8px;
				width: 112px;
				height: 42px;
				color: #FFFFFF;
				line-height: 42px;
				text-align: center;
				border-radius: 12px;
				background: #3773F5;
				font-size: 20px;
				cursor: pointer;
			}
			.buchongbox{
				width: 100%;
				height: 168px;
				padding: 28px 58px 0;
				box-sizing: border-box;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: #FFF;
				.wordbc{
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
				}
				.wordbc1{
					height: 58px;
					width: 100%;
					display: flex;
					align-items: center;
					color: #222;
					font-size: 20px;
					.text1{
						display: flex;
						align-items: center;
						font-size: 20px;
						span{
							color: #F50;
						}
					}
				}
				.inputlistbox{
					display: flex;
					align-items: center;
					.input{
						width: 418px;
						height: 42px;
						line-height: 42px;
						color: #000000;
						border-radius: 6px;
						border: 1px solid #0194FF;
						background: #F8F8F8;
						font-size: 16px;
						padding: 0 12px;
						box-sizing: border-box;
					}
				}
			}
		}
		.finish_box{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			.title1{
				margin-top: 18px;
				width: 432px;
				height: 63px;
			}
			.desc1{
				margin-top: 28px;
				width: 82%;
				height: 185px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				border-radius: 20px;
				border: 3px solid #50FFFF;
				background: radial-gradient(143.33% 75.16% at 17.95% 93.94%, #80ADFF 0%, #6FD9FE 100%);
				.text1{
					color: #F50;
					font-size: 64px;
					text-align: center;
				}
				.text2{
					color: #FFF;
					text-align: center;
					font-size: 32px;
				}
			}
			.conbox{
				width: 100%;
				margin-top: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				.text{
					color: #FFF;
					font-size: 28px;
					span{
						color: #273B90;
					}
				}
				.text2{
					margin-left: 28px;
				}
			}
			.btn1{
				margin-top: 48px;
				width: 160px;
				height: 48px;
				color: #0772FF;
				line-height: 48px;
				text-align: center;
				border-radius: 25px;
				background: linear-gradient(180deg, #FFF 0%, #C5FFFF 100%);
				font-size: 20px;
				cursor: pointer;
			}
		}
		
	}
	
</style>